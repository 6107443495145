
/* eslint-disable no-underscore-dangle */
import { namespace } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'
import WhyChoose from '~/components/LandingPage/WhyChoose.vue'
import SpecialOffer from '~/components/Promotions/SpecialOffer.vue'
import PlanPreviews from '~/components/LandingPage/PlanPreviews.vue'
import PromoPreview from '~/components/LandingPage/PromoPreview.vue'
import ServiceQualification from '~/components/ServiceQualification.vue'
import ServiceQualificationLong from '~/components/ServiceQualificationLong.vue'
import AddressBox from '~/components/LandingPage/AddressBox.vue'
import CheckAddress from '~/components/LandingPage/CheckAddress.vue'
import HelpOptions from '~/components/LandingPage/HelpOptions.vue'
import ConnectedIsEasy from '~/components/LandingPage/ConnectedIsEasy.vue'
import RedirectionLoading from '~/components/LandingPage/RedirectionLoading.vue'
import Reviews from '~/components/LandingPage/Reviews.vue'
import Tick2 from '~/components/Icons/TickAlt.vue'
import FaqList from '~/components/Faq/FaqList.vue'
import LottieAnimation from 'lottie-web-vue'
import LogoNew from '~/components/Brand/LogoNew.vue'

import * as sq from '~/store/modules/sq'
import * as cart from '~/store/modules/cart'
import * as faq from '~/store/modules/faq'
import * as review from '~/store/modules/review'

const partners = require('~/static/partners.json')

const SQ = namespace(sq.name)
const Cart = namespace(cart.name)
const Faq = namespace(faq.name)
const Review = namespace(review.name)

export {}
declare global {
  interface Window {
    __productReviewCallbackQueue: any
    __productReviewSettings: any
    zESettings: any
  }
}

@Component({
  components: {
    FaqList,
    WhyChoose,
    PlanPreviews,
    ServiceQualification,
    ServiceQualificationLong,
    CheckAddress,
    HelpOptions,
    SpecialOffer,
    ConnectedIsEasy,
    RedirectionLoading,
    Reviews,
    PromoPreview,
    AddressBox,
    LottieAnimation,
    LogoNew,
    Tick2
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand,
      zendeskId: process.env.zendesk
    }
  },
  head(): any {
    const titleText = 'OptiComm | Fibre Internet | High Speed | Broadband'
    return {
      title: `${this.brand.name} Internet - ${titleText}`,
      meta: [
        {
          name: 'description',
          content: `${
            this.brand.name
          } connects your homes and businesses to Super-Fast broadband. Choose from Wireless, Fibre Internet, Opticomm. Affordable prices. Sign up now.`
        }
      ],
      script: [
        {
          hid: 'chat',
          id: 'ze-snippet',
          src: `https://static.zdassets.com/ekr/snippet.js?key=${
            this.zendeskId
          }`,
          async: true
        },
        {
          hid: 'productreview',
          id: 'pr-snippet',
          src: `https://cdn.productreview.com.au/assets/widgets/loader.js`,
          async: true
        }
      ]
    }
  },
  mounted() {
    if (this.$route.query.salesPerson) {
      localStorage.setItem('salesPerson', this.$route.query.salesPerson)
    }

    if (this.$route.query.partner) { 
      const partner = partners.find(pa => pa.link === this.$route.query.partner)
      if (partner) {        
        this.updatePartner(partner.code)
        this.$router.push({ path: '/', query: { partner: partner.code } })
      }
    }
    
    if (this.$route.query.promo) {
      this.updatePromoDeal(this.$route.query.promo.toUpperCase())
    }

    window.__productReviewSettings = {
      brandId: `${this.brand.productreview}`
    }
    window.__productReviewCallbackQueue =
      window.__productReviewCallbackQueue || []
    window.__productReviewCallbackQueue.push(ProductReview => {
      ProductReview.use('reviews-horizontal', {
        identificationDetails: {
          type: 'single',
          strategy: 'from-internal-entry-id',
          identifier: `${this.brand.pridentifier}`
        },
        container: '#pr-reviews-horizontal-widget'
      })
    })

    window.__productReviewCallbackQueue.push(ProductReview => {
      ProductReview.use('inline-rating', {
        identificationStrategy: 'from-internal-entry-id'
      })
    })
    window.zESettings = {
      webWidget: {
        contactOptions: {
          enabled: true,
          chatLabelOnline: { '*': `${this.brand.name} Live Chat` },
          chatLabelOffline: { '*': 'Chat is unavailable' },
          contactFormLabel: { '*': 'Leave us a message' }
        },
        chat: {
          connectOnPageLoad: false,
          title: {
            '*': 'Chat with us!'
          },
          concierge: {
            avatarPath: `/zendesk/${this.siteId}-concierge.png`,
            name: `${this.brand.name} Chat Support`,
            title: { '*': 'Customer Service' }
          },
          offlineForm: {
            greeting: {
              '*': `Sorry, we aren't online right now. Please leave a message and we'll get back to you as soon as we can. -${
                this.brand.name
              }`
            }
          }
        },
        launcher: {
          chatLabel: {
            '*': 'Start chat'
          },
          badge: {
            image: `/zendesk/${this.siteId}-badge.png`,
            label: {
              '*': 'Live Chat'
            },
            layout: 'image_left'
          }
        }
      }
    }
  }
})
export default class Home extends Vue {
  @SQ.State
  sq

  @Cart.Getter
  cart

  @Cart.Action
  updateSalesPerson

  @Cart.Action
  updatePartner

  @Cart.Mutation
  setCoolingOffPeriod

  @Cart.Action
  fetchPromos

  @Cart.Action
  updatePromoDeal

  @Faq.Getter
  byType

  @Review.Getter
  reviews
  
  @SQ.Mutation
  setIsBusiness
  
  @Cart.Mutation
  setCampaign
  
  @Cart.Mutation
  setCampaignSource

  async created() {
    if (this.$route.query.salesPerson) {
      this.updateSalesPerson(this.$route.query.salesPerson)
      this.$router.push('/')
    }
    
    
    if (this.$route.query.coolingOffPeriod) {
      this.setCoolingOffPeriod(true)
    } else {      
      // reset to false if not in url
      this.setCoolingOffPeriod(false)
    }

    if (this.$route.query.campaign) { 
      this.setCampaign(this.$route.query.campaign)
    }
    if (this.$route.query.campaignsrc) { 
      this.setCampaignSource(this.$route.query.campaignsrc)
    }

    await this.setIsBusiness(false)
    
    this.fetchPromos()
  }
}
